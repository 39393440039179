<template>
    <b-card>
        <app-view-table :title="trans('placment',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :customColumns="custom_columns" :filterOptions="placements_status_list" :topFiltersCount="1" v-on:onRowSelected="getProfile" :isSearch="true" :isFilterOptions="true" class="table-selectable">

            <template v-slot:date_from="cell">
                {{(JSON.parse(cell.data.item.ids)).at(-1).date_from}}
            </template>

            <template v-slot:menu-items="cell">
                
                <b-dropdown-item :to="{ name: 'planning-placements-profile', params: { id_placement_data_main: JSON.parse(cell.data.item.ids).at(-1).id_placement_data_main }}">{{trans('placement-open-placement',222)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.id_client_data_main }}">{{trans('to-organization',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>
                                
            </template>

        </app-view-table>
    </b-card>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import UserSettings from '@core/scripts/UserSettings';
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    mounted(){
        this.onCreateThis();
    },
    
    watch:{},

    data(){
        return{
            custom_columns: ['date_from'],
            loading_list: false,
            items: [],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {    
                        clients: {type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},  
                        statuses: {type: 'list', changed: 0, values: [], function: "getPlacementsStatus", parameters: null, widthClass:'app-width-min-350'}, 
                        functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        person_types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        persons: {type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: null, widthClass:'app-width-min-250'},
                        types: {type: 'list', changed: 0, values: [], function: 'getPlacementsTypes', parameters: null, widthClass:'app-width-min-250'}

                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 4,
                changed: 0,
            },
            fields: [
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "120px"}, visible:true, filtering: true, filtering_name: "clients"},
                { key: "person_name", label: this.trans('flexworker',182), thStyle: { "min-width": "120px" }, visible: true, filtering: true, filtering_name: "persons"}, 
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "120px" }, visible: true, filtering: true, filtering_name: "functions"  },
                { key: "person_type_name", label: "Overeenkomst", thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "person_types"  },
                { key: "date_from", label: this.trans('date-from',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true},   
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "statuses"  },
                { key: "type_name", label: this.trans('type',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "types"  },  
            ],
            placements_status_list: [
                { value: 1, name: this.trans('my-clients',3) },
                { value: 2, name: this.trans('my-flex',179) },               
                { value: 3, name: this.trans('my-locations',179) },
                { value: 4, name: this.trans('placements-my-concept-placements',222) },
                { value: 5, name: this.trans('placement-all-placements',222) },
            ],
            userSettings: new UserSettings(),
        }
    },

    methods: {

        getList: function(){

            this.loading_list = true;

            axios
                .post("planning/placements/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })

        },

        getProfile(selectedRows){

            if(selectedRows.length != 0){
                this.$router.push({ name: "planning-placements-profile", params: { id_placement_data_main: JSON.parse(selectedRows[0].ids).at(-1).id_placement_data_main } });
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 16);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.placementsList == undefined){
                await this.userSettings.saveSettings(this.filters, 16);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
              
            var sFilters = getData.placementsList

            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting; 
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;        
        },

        onCreateThis(){
            this.loadSettings();

            this.getList();

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.clients.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.person_types.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                   this.getList();
                }   
            });

        }

    }

    
}
</script>
<style scoped>

.app-local-group-item{

    border: none;
}

.app-local-group-item:hover{
    background-color: #EDF7FB;
}

</style>